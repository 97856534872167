<template>
    <div id="account-setting" class="page-content">
        <div class="mdl-grid">
            <header class="header-grey">
                <div class="header-grey-row">
                    <span class="mdl-layout__title wrapper-title">
                        <span class="pre">{{ $t('account-settings.title')}}</span>
                    </span>

                    <div class="mdl-layout-spacer"></div>

                    <!-- Deletable Chip -->
                    <span class="mdl-chip mdl-chip--deletable">
                        <span class="mdl-chip__text">{{ $t('account-settings.delete-account') }}</span>
                        <button @click="onDeleteModal" type="button" class="margin sweet-alert-button mdl-chip__action"><i class="material-icons">cancel</i></button>
                    </span>
                </div>
            </header>
        </div>


        <div class="mdl-grid">
            <form @submit.prevent="handleSubmit">

                <div class="mdl-grid">

                    <div v-if="account" class="mdl-cell mdl-cell--4-col mdl-cell--8-col-tablet mdl-cell--4-col-phone">
                        <div id="msg-company-profile" class="mdl-textfield mdl-js-textfield company-profile-photo">
                            <div class="mask-photo">
                                <img class="photo-image" :src="account.logo" id="account-logo"
                                     alt="Company logo image">
                            </div>
                            <div class="wrapper-image">
                                <label for="upload-logo" class="add">
                                    <span class="material-icons">cloud_upload</span>
                                    <span class="upload-text">{{ $t('account-settings.change-logo')}}</span>
                                </label>
                            </div>
                            <input id="upload-logo" @change="onFileSelected" accept="image/*" type="file" name="files"/>
                            <span id="msg-profile-error"
                                  class="mdl-textfield__error">We haven't found your picture</span>
                            <span class="mdl-textfield__success">Photo OK!</span>
                        </div>
                    </div>


                    <div class="mdl-cell mdl-cell--4-col mdl-cell--8-col-tablet mdl-cell--4-col-phone">

                        <div id="msg-company-name"
                             class="wrapper-input mdl-textfield mdl-js-textfield mdl-textfield--floating-label is-dirty">
                            <input class="mdl-textfield__input input-add-company" type="text" v-model="account.name"
                                   maxlength="50" id="account-name" aria-required="true" required>
                            <label class="mdl-textfield__label" for="account-name">{{ $t('account-settings.name')}}</label>
                            <span class="mdl-textfield__error">Name is required</span>
                            <span class="mdl-textfield__success">Input OK!</span>
                        </div>

                        <div id="msg-company-email"
                             class="wrapper-input wrapper-input mdl-textfield mdl-js-textfield mdl-textfield--floating-label is-dirty">
                            <input class="mdl-textfield__input input-add-company" type="email" v-model="account.email"
                                   id="account-email" autocomplete="email"
                                   pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$" aria-required="true"
                                   required>
                            <label class="mdl-textfield__label" for="account-email">{{ $t('account-settings.email')}}</label>
                            <span class="mdl-textfield__error">Email is required</span>
                            <span class="mdl-textfield__success">Input OK!</span>
                        </div>

                        <div id="msg-password"
                             class="wrapper-input mdl-textfield mdl-js-textfield mdl-textfield--floating-label is-dirty">
                            <input class="mdl-textfield__input input-add-password" type="password"
                                   id="account-password"
                                   v-model="password">
                            <label class="mdl-textfield__label" for="account-password">{{ $t('shared.password')}}</label>
                            <span class="mdl-textfield__error">Password must be at least 6 characters</span>
                            <span class="mdl-textfield__success">Input OK!</span>
                        </div>

                        <div id="msg-password-confirm"
                             class="wrapper-input mdl-textfield mdl-js-textfield mdl-textfield--floating-label is-dirty">
                            <input class="mdl-textfield__input input-add-password" type="password"
                                   id="account-password-confirm"
                                   v-model="passwordVerify">
                            <label class="mdl-textfield__label" for="account-password-confirm">{{ $t('shared.password-confirm')}}</label>
                            <span class="mdl-textfield__error">Passwords must match</span>
                            <span class="mdl-textfield__success">Password OK!</span>
                        </div>

                    </div>


                    <div class="mdl-cell mdl-cell--4-col mdl-cell--8-col-tablet mdl-cell--4-col-phone">

                        <div id="msg-company-language"
                            class="wrapper-input mdl-textfield mdl-js-textfield mdl-textfield--floating-label is-dirty">
                            <label class="mdl-textfield__label language" for="account-language">{{ $t('account-settings.language') }}</label>

                            <md-field class="md-select-overwrite" id="account-language">
                                <md-select class="mdl-textfield__input input-add-company" name="Account language" v-model="account.settings.lang">
                                    <md-option value="en">{{ $t('account-settings.english')}}</md-option>
                                    <md-option value="es">{{ $t('account-settings.spanish')}}</md-option>
                                </md-select>
                            </md-field>


                        </div>

                        <div id="msg-company-website"
                             class="wrapper-input mdl-textfield mdl-js-textfield mdl-textfield--floating-label is-dirty">
                            <input class="mdl-textfield__input input-add-company" type="text" v-model="account.website"
                                   id="account-website">
                            <label class="mdl-textfield__label" for="account-website">{{
                                $t('account-settings.website')}}</label>
                            <span class="mdl-textfield__error">Website is required</span>
                            <span class="mdl-textfield__success">Input OK!</span>
                        </div>

                        <div id="msg-company-address"
                             class="wrapper-input mdl-textfield mdl-js-textfield mdl-textfield--floating-label is-dirty">
                            <input class="mdl-textfield__input input-add-company" type="text" v-model="account.billing"
                                   id="account-address"/>
                            <label class="mdl-textfield__label" for="account-address">{{
                                $t('account-settings.address')}}</label>
                            <span class="mdl-textfield__error">Address is required</span>
                            <span class="mdl-textfield__success">Input OK!</span>
                        </div>
                    </div>

                </div>

                <div class="mdl-grid">
                    <div class="mdl-cell mdl-cell--12-col mdl-cell--8-col-tablet mdl-cell--4-col-phone">
                        <p id="msg-error" class="mdl-textfield__error">Error!</p>
                        <p id="msg-success" class="mdl-textfield__success">Settings updated!</p>
                    </div>
                    <div class="mdl-cell mdl-cell--12-col mdl-cell--8-col-tablet mdl-cell--4-col-phone wrapper-settings-buttons">

                        <button type="submit" id="submit"
                                class="mdl-button mdl-button--colored mdl-button--raised mdl-js-button mdl-js-ripple-effect">
                            {{ $t('shared.save')}}
                        </button>
                    </div>
                </div>
                <vue-element-loading :active="show" spinner="bar-fade-scale" color="#FF6700"/>
            </form>
        </div>
    </div>
</template>

<style scope lang="scss">
    form.velmld-parent {
        width: 100%;
    }
</style>

<script>
    import {mapActions, mapState} from 'vuex'
    import VueElementLoading from 'vue-element-loading'

    export default {
        name: "AccountSettings",
        created() {
            this.account = Object.assign({}, this.original)
        },
        data() {
            return {
                account: [],
                logoChanged: false,
                password: '',
                passwordVerify: '',
            }
        },
        computed: {
            ...mapState({
                show: state => state.account.requesting,
                original: state => state.account.account
            }),
        },
        methods: {
            ...mapActions('account', ['updateAccount', 'deleteAccount']),
            onFileSelected(event) {
                let file = event.target.files[0];
                let img = document.getElementById('account-logo')
                if (file) {
                    const reader = new FileReader();
                    reader.onload = (e) => img.src = e.target.result
                    reader.readAsDataURL(file)
                    this.logoChanged = true
                }
            },
            onDeleteModal() {
                this.$swal({
                    title: this.$i18n.t('account-settings.delete-modal-title'),
                    confirmButtonText: this.$i18n.t('account-settings.delete-modal'),
                    text: this.$i18n.t('account-settings.delete-modal-text'),
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        this.delete()
                    }
                })
            },
            async delete() {
                try {
                    await this.deleteAccount()
                } catch(error) {
                    this.$toast.error(error.message)
                }
            },
            async handleSubmit() {
                if (this.password !== this.passwordVerify) {
                    this.$toast.error(this.$i18n.t('shared.password-mismatch'))
                    return
                }

                try {
                    const update = {
                        name: this.account.name,
                        email: this.account.email,
                        billing: this.account.billing,
                        website: this.account.website,
                        lang: this.account.settings.lang,
                    }
                    if (this.password !== '') {
                        update['password'] = this.password
                    }
                    if (this.logoChanged) {
                        update['logo'] = document.getElementById('account-logo').src
                    }
                    await this.updateAccount(update)
                    this.$toast.success(this.$i18n.t('account-settings.updated'))
                    this.logoChanged = false
                } catch (error) {
                    this.$toast.error(this.$i18n.t(error.message))
                }
            },
        },
        components: {
            VueElementLoading
        }
    }
</script>
